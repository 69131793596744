<template>
	<div class="page-width">
		<datatable title="Suppliers" :operations="operations" :response="data" :fields="fields" :filters="filters" :is_createable="false" :show_edit="false" 
				   :show_delete="false" @publish_position="publishPosition">
		</datatable>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import FiltersHelper from '@/classes/filters_helper.js'
	import Datatable from '@/components/Datatable'
	export default {
		name: 'suppliers',
		data(){
			let filters_helper = new FiltersHelper()
			return {
				filters_helper,
				fields: [
				    {
				      name: 'Status',
				      value: 'status'
				    },
				    {
				      name: 'Username',
				      value: 'username'
				    },
				    {
				      name: 'is_synced',
				      value: 'is_synced',
				    },
				    {
				      name: 'slots_size',
				      value: 'slots_size',
				    },
				    {
				      name: 'Outsource_Class',
				      value: 'outsource_class',
				    },
				    {
				      name: 'created_at',
				      value: 'created_at',
				      is_time: true
				    },
				    {
				      name: 'updated_at',
				      value: 'updated_at',
				      is_time: true
				    },
				],
				data: [],
				filters: [
			        {
			          type: 'single',
			          designator: 'outsource_class',
			          options: filters_helper.getOutsourcesAsFilters()
			        },
					{
					  type: 'single',
					  designator: 'status',
					  options: [
					  	{
					  		text: 'active',
					  		value: 'active'
					  	},
					  	{
					  		text: 'expired',
					  		value: 'expired'
					  	}
					  ]
					},
				],
				operations: [
					{ name: 'Slots', component: 'SlotsRouterLink' },
					{ display_text: 'Publish Position', name: 'publish_position' }
				]
			}
		},
		created(){
			this.init()
		},
		methods: {
			init(){
				this.$network_utils.getShuffleSuppliers({
					...this.filters_helper.getServerCallFilters(this.filters),
				}).then(response => {
	          		response = Array.isArray(response) ? response : [response]
					this.data = response
				})
			},
			publishPosition(data){
				this.createModal({
					designator: 'publish-position-modal',
					component_name: 'PublishPositionForm',
					supplier_id: data.id,
					modal_title: `Publish Position - ${data.username} - ${data.outsource_class}`
				})
			},
			...mapMutations('modals', ['createModal'])
		},
		components: {
			Datatable
		}
	}
</script>